import React, {useContext} from 'react';
import {FormularioContext} from '../context/formularioContext';

const AvisoLegal = () => {
    const { responsable, sitioWeb, cif, direccion, email, politicasPrivacidad, actividadSocial, nombreCompleto   } = useContext(FormularioContext);
    return (
        <div>
            <h1>AVISO LEGAL Y TÉRMINOS DE USO</h1>
            <p>Puedo garantizar que te encuentras en un espacio 100 % seguro, por ello, dando cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, a continuación se hace constar:</p>
            <h3>1.1. DATOS IDENTIFICATIVOS DEL RESPONSABLE</h3>
            <p>Tal y como recoge la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, le informo que:</p>
            <ul>
                <li>Mi nombre es: { nombreCompleto }</li>
                <li>Mi denominación social es: { responsable }</li>
                <li>Mi {cif.tipo} es: { cif.numero }</li>
                <li>Mi domicilio social se encuentra en { direccion }</li>
                <li>Email: { email }</li>
                <li>Su actividad social es: {actividadSocial}</li>
            </ul>
            <h3> 1.2. FINALIDAD DE LA PÁGINA WEB.</h3>
            <p>Los servicios prestados por el responsable de la página web son los siguientes:</p>
            <ul>
                <li>Ofrecer servicios relacionados con el marketing digital y la creación de contenidos.</li>
                <li>Suministrar artículos,contenido y vídeos sobre marketing digital en español.</li>
                <li>Gestionar la lista de suscriptores y usuarios adscritos al curso.</li>
                <li>La venta de formación y servicios sobre marketing digital</li>
                <li>Suministro de contenidos en el blog</li>
                <li>La venta de Ebooks</li>
            </ul>
            <h3>1.3. USUARIOS:</h3>
            <p>El acceso y/o uso de este sitio web atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, los presentes términos de uso, no obstante, por el mero uso de la página web no significa el inicio de relación laboral/comercial alguna.</p>
            <h3>1.4. USO DEL SITIO WEB Y CAPTURA DE INFORMACIÓN:</h3>
            <p>1.4.1 USO DEL SITIO WEB</p>
            <p>La página web { sitioWeb } en adelante ({ responsable }) proporciona el acceso a artículos, informaciones, servicios y datos (en adelante, "los contenidos") propiedad de { responsable }. El USUARIO asume la responsabilidad del uso de la web.</p>
            <p>El USUARIO se compromete a hacer un uso adecuado de los contenidos que ofrece a través de su web y con carácter enunciativo pero no limitativo, a no emplearlos para:</p>
            <p>(a) incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público;</p>
            <p>(b) difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos;</p>
            <p>(c) provocar daños en los sistemas físicos y lógicos de { sitioWeb } , de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados;</p>
            <p>(d) intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes.</p>
            <p>{ responsable } se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su publicación.</p>
            <p>En cualquier caso, { responsable } no será responsable de las opiniones vertidas por los usuarios a través del blog u otras herramientas de participación que puedan crearse, conforme a lo previsto en la normativa de aplicación.</p>
            <h3>1.4.2 CAPTURA DE INFORMACIÓN</h3>
            <p>&ndash; Formulario de contacto, donde el USUARIO deberá rellenar el campo de correo electrónico, asunto y nombre.</p>
            <p>&ndash; Formulario de suscripción, rellenando el USUARIO los campos necesarios para la adquisición del curso con los campos de nombre, apellidos, dirección, ciudad, país, estado, código postal, email y contraseña.</p>
            <p>&ndash; Cookies de rastreo, conforme a las siguientes reglas.</p>
            <p>&ndash; Navegación y Dirección IP: Al navegar por esta web, el usuario facilita de forma automática al servidor de la web información relativa a tu dirección IP, fecha y hora de acceso, el hipervínculo que le ha reenviado a éstas, tu sistema operativo y el navegador utilizado.</p>
            <p>A pesar de lo anterior, los usuarios podrán darse de baja en cualquier momento de los servicios prestados por { responsable } o datos aportados por el USUARIO dando cumplimiento a la normativa vigente sobre Protección de Datos.</p>
            <p>Asimismo, tanto al suscribirse a esta página web como al realizar algún comentario en cualquiera de sus páginas y/o entradas, el usuario consiente:</p>
            <ol>
                <li>El tratamiento de sus datos personales en el entorno de WordPress conforme a <a href="http://automattic.com/privacy/">sus políticas de privacidad</a>.</li>
                <li>El acceso de { responsable } a los datos que, de acuerdo con la infraestructura de WordPress, necesite el usuario aportar bien para la suscripción al curso bien para cualquier consulta mediante el formulario de contacto.</li>
            </ol>
            <p>Asimismo, informamos que la información de nuestros usuarios está protegida de acuerdo a nuestra <a href={ politicasPrivacidad }> política de privacidad.</a></p>
            <p>Al activar una suscripción, formulario de contacto o comentario, el usuario comprende y acepta que:</p>
            <p>Desde el momento en que efectúa su suscripción o accedes a algún servicio de pago, { responsable } tiene acceso.</p>
            <p>a: Nombre, y email, o demás datos necesarios para facturación conformando un fichero debidamente inscrito en el Registro General de la <a href="http://www.agpd.es/">Agencia Española de Protección de Datos</a> con el nombre de "USUARIOS DE LA WEB Y SUSCRIPTORES" o "CLIENTES Y/O PROVEEDORES"</p>
            <p>En todo caso { responsable } se reserva el derecho de modificar, en cualquier momento y sin necesidad de previo aviso, la presentación y configuración de la web { sitioWeb } como el presente aviso legal.</p>
            <h2>2. PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
            <p>{ responsable } por sí o como cesionario, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de { responsable } o bien de sus licenciantes. Todos los derechos reservados.</p>
            <p>Cualquier uso no autorizado previamente por { responsable } , será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor.</p>
            <p>Quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de { responsable }.</p>
            <p>El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de { responsable }. Podrá visualizar únicamente los elementos de la web sin posibilidad de imprimirlos, copiarlos o almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico. El USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas de { responsable }.</p>
            <p>Queda terminantemente prohibido compartir la licencia para uso con más personas, cada licencia es personal e intransferible reservándonos cuantas acciones civiles y penales nos asistan en aras de salvaguardar nuestros derechos, todo ello bajo apercibimiento de incurrir en un delito contra la propiedad intelectual del art. 270 y ss del Código Penal con penas de prisión de hasta 4 años.</p>
            <h2>3. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD </h2>
            <p>{ responsable } no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: por errores u omisiones en los contenidos, por falta de disponibilidad del sitio web, &ndash; el cual realizará paradas periódicas por mantenimientos técnicos &ndash; así como por la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.</p>
            <h2>4. MODIFICACIONES</h2>
            <p>{ responsable } se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados en su web.</p>
            <h2>5. POLÍTICA DE ENLACES</h2>
            <p>Las personas o entidades que pretendan realizar o realicen un hiperenlace desde una página web de otro portal de Internet a la web de { responsable } deberá someterse las siguientes condiciones:</p>
            <p>&ndash; No se permite la reproducción total o parcial de ninguno de los servicios ni contenidos del sitio web sin la previa autorización expresa de { responsable }.</p>
            <p>&ndash; No se establecerán deep-links ni enlaces IMG o de imagen, ni frames con la web de { responsable } sin su previa autorización expresa.</p>
            <p>&ndash; No se establecerá ninguna manifestación falsa, inexacta o incorrecta sobre la web de { responsable }, ni sobre los servicios o contenidos de la misma. Salvo aquellos signos que formen parte del hipervínculo, la página web en la que se establezca no contendrá ninguna marca, nombre comercial, rótulo de establecimiento, denominación, logotipo, eslogan u otros signos distintivos pertenecientes a { responsable }, salvo autorización expresa de éste.</p>
            <p>&ndash; El establecimiento del hipervínculo no implicará la existencia de relaciones entre { responsable } y el titular de la página web o del portal desde el cual se realice, ni el conocimiento y aceptación de { responsable } de los servicios y contenidos ofrecidos en dicha página web o portal.</p>
            <p>&ndash; { responsable } no será responsable de los contenidos o servicios puestos a disposición del público en la página web o portal desde el cual se realice el hipervínculo, ni de las informaciones y manifestaciones incluidas en los mismos.</p>
            <p>El sitio web de { responsable } puede poner a disposición del usuario conexiones y enlaces a otros sitios web gestionados y controlados por terceros. Dichos enlaces tienen como exclusiva función, la de facilitar a los usuarios la búsqueda de información, contenidos y servicios en Internet, sin que en ningún caso pueda considerarse una sugerencia, recomendación o invitación para la visita de los mismos.</p>
            <p>{ responsable } no comercializa, ni dirige, ni controla previamente, ni hace propios los contenidos, servicios, informaciones y manifestaciones disponibles en dichos sitios web.</p>
            <p>{ responsable } no asume ningún tipo de responsabilidad, ni siquiera de forma indirecta o subsidiaria, por los daños y perjuicios de toda clase que pudieran derivarse del acceso, mantenimiento, uso, calidad, licitud, fiabilidad y utilidad de los contenidos, informaciones, comunicaciones, opiniones, manifestaciones, productos y servicios existentes u ofrecidos en los sitios web no gestionados por { responsable } y que resulten accesibles a través de { responsable }.</p>
            <h2>6. DERECHO DE EXCLUSIÓN</h2>
            <p>{ responsable } se reserva el derecho a denegar o retirar el acceso al portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso.</p>
            <h2>7. GENERALIDADES</h2>
            <p> { responsable } perseguirá el incumplimiento de las presentes condiciones así como cualquier utilización indebida de su web ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.</p>
            <h2>8. MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</h2>
            <p>{ responsable } podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen. La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</p>
            <h2>9. RECLAMACIONES Y DUDAS</h2>
            <p>{ responsable } informa que existen hojas de reclamación a disposición de usuarios y clientes pudiendo remitir un correo a { email } indicando su nombre y apellidos, el servicio o producto adquirido y exponiendo los motivos de su reclamación.</p>
            <p>También puede dirigir su reclamación por correo postal dirigido a: { responsable }. { direccion }.</p>
            <h2>10. LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h2>
            <p>La relación entre { responsable } y el USUARIO se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y tribunales de la ciudad de Madrid, salvo que la Ley aplicable disponga otra cosa.</p>
        </div>
    )
}

export default AvisoLegal;

import React, {useContext} from 'react';
import {FormularioContext} from '../context/formularioContext';

const Navegacion = () => {

    const {
        nombreCompleto, setNombreCompleto,
        responsable, setResponsable,
        nombreComercial, setNombreComercial,
        cif, setCif,
        direccion, setDireccion,
        email, setEmail,
        actividadSocial, setActividadSocial,
        sitioWeb, setSitioWeb,
        politicasPrivacidad, setPoliticasPrivacidad,
        nombreHosting, setNombreHosting,
        tirdEmailCamp, setTirdEmailCamp,
        tirdURLEmailCamp, setTirdURLEmailCamp,
        tirdWPTheme, setTirdWPTheme,
        tirdURLWPTheme, setTirdURLWPTheme,
        tirdPasarelaPago, setTirdPasarelaPago,
        tirdContabilidad, settirdContabilidad,
        tirdWebinars, setTirdWebinars,
        tirdURLWebinars, setTirdURLWebinars,
        tirdAlojamientoCursos, setTirdAlojamientoCursos,
        tirdURLAlojamientoCursos, setTirdURLAlojamientoCursos,
        tirdPlataformaPago, setTirdPlataformaPago,
        tirdURLPlataformaPago, setTirdURLPlataformaPago,
        responsableHosting, setResponsableHosting,
        urlHosting, setUrlHosting,
        direccionHosting, setDireccionHosting,
        cifHosting, setCifHosting,
        legalHosting, setLegalHosting} = useContext(FormularioContext);

        const actualizarCif = (e) => {
            setCif({
                ...cif,
                [e.target.name] : e.target.value 
            })
        }

    return (
        <div>
            <form>
                <div className="form-group">
                    <label htmlFor="NombreCompleto">Nombre Completo</label>
                    <input 
                        type="text"
                        id="NombreCompleto"
                        className="form-control"
                        value={nombreCompleto}
                        onChange={(e) => setNombreCompleto(e.target.value)}
                    />                        
                </div>
                <div className="form-group">
                    <label htmlFor="responsable">Responsable</label>
                    <input
                        id="responsable" 
                        type="text"
                        className="form-control"
                        value={responsable}
                        onChange={(e) => setResponsable(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-8">
                        <label htmlFor="CIF">CIF</label>
                            <input
                                id="cif" 
                                type="text"
                                name="numero"
                                className="form-control"
                                value={cif.numero}
                                onChange={(e) => actualizarCif(e)}
                            />
                        </div>
                        <div className="col-4">
                                <select class="form-control" name="tipo" onChange={(e) => actualizarCif(e)} style={{marginTop: '20px'}} id="tipo">
                                    <option value="CIF/NIF" defaultValue>CIF</option>
                                    <option value="DNI">DNI</option>
                                    <option value="NIE">NIE</option>
                                </select>
                            </div>
                        </div>               
                    
                    <small className ="form-text text-muted">CIF / DNI / NIE</small>
                </div>
                <div className="form-group">
                    <label htmlFor="direccion">Dirección</label>
                    <input
                        id="direccion" 
                        type="text"
                        className="form-control"
                        value={direccion}
                        onChange={(e) => setDireccion(e.target.value)}
                    />
                    <small className ="form-text text-muted">Ej. Calle falsa, 123</small>
                </div>
                <div className="form-group">
                    <label htmlFor="email">email</label>
                    <input
                        id="email" 
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <small className ="form-text text-muted">Ej. info@desarrollatonline.com</small>
                </div>

                <div className="form-group">
                    <label htmlFor="actividadSocial">Actividad Social</label>
                    <input
                        id="actividadSocial" 
                        type="text"
                        className="form-control"
                        value={actividadSocial}
                        onChange={(e) => setActividadSocial(e.target.value)}
                    />
                    <small className ="form-text text-muted">Ej. Blogging y Negocios Online.</small>                    
                </div>
                <div className="form-group">
                    <label htmlFor="sitioWeb">Sitio web</label>
                    <input
                        id="sitioWeb" 
                        type="text"
                        className="form-control"
                        value={sitioWeb}
                        onChange={(e) => setSitioWeb(e.target.value)}
                    />
                    <small className ="form-text text-muted">Ej. https://www.desarrollatonline.com</small> 
                </div>
                <div className="form-group">
                    <label htmlFor="politicasPrivacidad">Politicas de privacidad del sitio web</label>
                    <input
                        id="politicasPrivacidad" 
                        type="text"
                        className="form-control"
                        value={politicasPrivacidad}
                        onChange={(e) => setPoliticasPrivacidad(e.target.value)}
                    />
                    <small className ="form-text text-muted">Ej. https://www.desarrollatonline.com/politicas-de-privacidad</small> 
                </div>
                <div className="form-group">
                    <label htmlFor="nombreHosting">Hosting en donde está alojada la web</label>
                    <input
                        id="nombreHosting" 
                        type="text"
                        className="form-control"
                        value={nombreHosting}
                        onChange={(e) => setNombreHosting(e.target.value)}
                    />
                    <small className ="form-text text-muted">Nombre del hosting en donde se encuentra alojada la web</small>
                </div>
                {/* Datos de terceros */}
                <div className="form-group">
                    <label htmlFor="tirdEmailCamp">Plataforma de email marketing</label>
                    <input
                        id="tirdEmailCamp" 
                        type="text"
                        className="form-control"
                        value={tirdEmailCamp}
                        onChange={(e) => setTirdEmailCamp(e.target.value)}
                    />
                    <small className="form-text text-muted">Ejemplo: Active campaing, GetResponse, MailChimp</small>
                </div>
                <div className="form-group">
                    <label htmlFor="tirdURLEmailCamp">URL de la Plataforma de email marketing</label>
                    <input
                        id="tirdURLEmailCamp" 
                        type="text"
                        className="form-control"
                        value={tirdURLEmailCamp}
                        onChange={(e) => setTirdURLEmailCamp(e.target.value)}
                    />
                    <small className="form-text text-muted">Ej: https://www.activecampaign.com/es/</small>
                </div>
                <div className="form-group">
                    <label htmlFor="tirdWPTheme">Plantilla o theme wordpress</label>
                    <input
                        id="tirdWPTheme" 
                        type="text"
                        className="form-control"
                        value={tirdWPTheme}
                        onChange={(e) => setTirdWPTheme(e.target.value)}
                    />
                    <small className="form-text text-muted">Tema de wordpress usado para crear la web. En este caso esta hecho con Hello. lo puedes cambiar</small>
                </div>
                <div className="form-group">
                    <label htmlFor="tirdURLWPTheme">Direccion de los creadores del tema de wordpress</label>
                    <input
                        id="tirdURLWPTheme" 
                        type="text"
                        className="form-control"
                        value={tirdURLWPTheme}
                        onChange={(e) => setTirdURLWPTheme(e.target.value)}
                    />
                    <small className="form-text text-muted">Ej: https://elementor.com/hello-theme/</small>
                </div>

                {/* Datos del hosting */}
                <div className="form-group">
                    <label htmlFor="nombreHosting">Hosting en donde está alojada la web</label>
                    <input
                        id="nombreHosting" 
                        type="text"
                        className="form-control"
                        value={nombreHosting}
                        onChange={(e) => setNombreHosting(e.target.value)}
                    />
                    <small className="form-text text-muted">Nombre del hosting en donde se encuentra alojada la web</small>
                </div>
                <div className="form-group">
                    <label htmlFor="responsableHosting">Responsable del hosting</label>
                    <input
                        id="responsableHosting" 
                        type="text"
                        className="form-control"
                        value={responsableHosting}
                        onChange={(e) => setResponsableHosting(e.target.value)}
                    />
                    <small className="form-text text-muted">Persona al frente de la empresa de Hosting</small>
                </div>
                <div className="form-group">
                    <label htmlFor="urlHosting">URL Web del hosting</label>
                    <input
                        id="urlHosting" 
                        type="text"
                        className="form-control"
                        value={urlHosting}
                        onChange={(e) => setUrlHosting(e.target.value)}
                    />
                    <small className="form-text text-muted">La URL del hosting</small>
                </div>
                <div className="form-group">
                    <label htmlFor="direccionHosting">Direccion del hosting</label>
                    <input
                        id="direccionHosting" 
                        type="text"
                        className="form-control"
                        value={direccionHosting}
                        onChange={(e) => setDireccionHosting(e.target.value)}
                    />
                    <small className="form-text text-muted">La dirección en donde se encuentra la empresa</small>
                </div>
                <div className="form-group">
                    <label htmlFor="cifHosting">CIF del Hosting</label>
                    <input
                        id="cifHosting" 
                        type="text"
                        className="form-control"
                        value={cifHosting}
                        onChange={(e) => setCifHosting(e.target.value)}
                    />
                    <small className="form-text text-muted">CIF Del hosting</small>
                </div>
                <div className="form-group">
                    <label htmlFor="legalHosting">Politicas de Privacidad del hosting</label>
                    <input
                        id="legalHosting" 
                        type="text"
                        className="form-control"
                        value={legalHosting}
                        onChange={(e) => setLegalHosting(e.target.value)}
                    />
                    <small className="form-text text-muted">Politicas de privacidad o aviso legal del hosting en donde está alojada la web</small>
                </div>
                
                
            </form>
        </div>
    )
}

export default Navegacion

import React, { useContext } from 'react';
import {FormularioContext} from '../context/formularioContext';

const PoliticaCookies = () => {
    const fecha = new Date();
    const { responsable, sitioWeb, cif, direccion, email, actividadSocial, responsableHosting, hosting, direccionHosting, cifHosting, urlHosting, nombreHosting, tirdURLEmailCamp, tirdEmailCamp, tirdURLWPTheme, tirdWPTheme, tirdURLPasarelaPago, tirdPasarelaPago, tirdURLContabilidad, tirdContabilidad, tirdURLAlojamientoCursos, tirdAlojamientoCursos, tirdURLPlataformaPago, tirdPlataformaPago } = useContext(FormularioContext);
    return (
        <div>
            <h1>POLÍTICA DE COOKIES</h1>
            <p> LSSI-CE, obliga a todos los que tenemos una web o negocio online a advertir al usuario de la existencia de cookies, informar sobre ellas y requerirle permiso para descargarlas.</p>
            <p>Según lo dispuesto en el art.<em> 22.2 de la Ley 34/2002. "Los prestadores de servicios podrán utilizar dispositivos de almacenamiento y recuperación de datos en equipos terminales de los destinatarios, a condición de que los mismos </em>hayan dado su consentimiento después de que se les haya facilitado información clara y completa sobre su utilización<em>, en particular, sobre los fines del tratamiento de los datos, con arreglo a lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal".</em></p>
            <p>Como responsable de esta web, me he esmerado en cumplir con el máximo rigor el artículo 22.2 el de la Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico referente a las cookies, no obstante, teniendo en cuenta la forma en la que funciona Internet y los sitios web, no siempre es posible contar con información actualizada de las cookies que terceras partes puedan utilizar a través de este sitio web.</p>
            <p>Esto se aplica especialmente a casos en los que esta página web contiene elementos integrados: es decir, textos, documentos, imágenes o breves películas que se almacenan en otra parte, pero se muestran en nuestro sitio web.</p>
            <p>Por consiguiente, en caso de que te encuentres con este tipo de cookies en este sitio web y no estén enumeradas en la lista siguiente, te ruego que me lo comuniques. También puedes ponerte en contacto directamente con el tercero para pedirle información sobre las cookies que coloca, la finalidad y la duración de la cookie, y cómo ha garantizado su privacidad.</p>
            <h2>Previo.- &iquest;QUÉ SON LAS COOKIES?</h2>
            <p>Las cookies constituyen una herramienta empleada por los servidores Web para almacenar y recuperar información acerca de sus visitantes. No es más que un fichero de texto que algunos servidores piden a nuestro navegador que escriba en nuestro disco duro, con información acerca de lo que hemos estado haciendo por sus páginas. Poseen una fecha de caducidad, que puede oscilar desde el tiempo que dure la sesión hasta una fecha futura especificada, a partir de la cual dejan de ser operativa.</p>
            <h2>COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXCEPTUADAS</h2>
            <p>Según la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las cookies de analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del sitio web o la prestación de servicios expresamente solicitados por el usuario.</p>
            <h2>&iquest;QUÉ TIPOS DE COOKIES EXISTEN?</h2>
            <p>Sobre los tipos de cookies, existen cinco grandes grupos:</p>
            <p>&ndash; Cookies analíticas: recogen información del uso que se realiza del sitio web.</p>
            <p>&ndash; Cookies sociales: son aquellas necesarias para redes sociales externas.</p>
            <p>&ndash; Cookies de afiliados: permiten hacer un seguimiento de las visitas procedentes de otras webs, con las que el sitio web establece un contrato de afiliación (empresas de afiliación).</p>
            <p>&ndash; Cookies de publicidad y comportamentales: recogen información sobre las preferencias y elecciones personales del usuario (retargeting).</p>
            <p>&ndash; Cookies técnicas y funcionales: son las estrictamente necesarias para el uso del sitio web y para la prestación del servicio contratado.</p>
            <h2>1. Las cookies que utiliza esta web</h2>
            <p>En esta web se utilizan cookies propias y de terceros para conseguir que tengas una mejor experiencia de navegación, puedas compartir contenido en redes sociales, para mostrarte anuncios en función a tus intereses y para obtener estadísticas de usuarios.</p>
            <p>Las cookies empleadas en { sitioWeb } se asocian únicamente con un Usuario anónimo y su ordenador, no proporcionan referencias que permitan deducir el nombre y apellidos del Usuario y no pueden leer datos de su disco duro ni incluir virus en sus textos. Asimismo, <a href={ sitioWeb } > { sitioWeb } </a> no puede leer las cookies implantadas en el disco duro del Usuario desde otros servidores.</p>
            <p>El usuario puede libremente decidir acerca de la implantación o no en su disco duro de las cookies empleadas en { sitioWeb } En este sentido, el usuario puede configurar su navegador para aceptar o rechazar por defecto todas las cookies o para recibir un aviso en pantalla de la recepción de cada cookie y decidir en ese momento su implantación o no en su disco duro. Para ello le sugerimos consultar la sección de ayuda de su navegador para saber cómo cambiar la configuración que actualmente emplea. Aun cuando el Usuario configurase su navegador para rechazar todas las cookies o rechazase expresamente las cookies de { sitioWeb } podrá navegar por el Portal con el único inconveniente de no poder disfrutar de las funcionalidades del Portal que requieran la instalación de alguna de ellas. En cualquier caso, el Usuario podrá eliminar las cookies implantadas en su disco duro en cualquier momento, siguiendo el procedimiento establecido en la sección de ayuda de su navegador y que posteriormente detallaremos.</p>
            <p>Como usuario, puedes rechazar el tratamiento de los datos o la información bloqueando estas cookies mediante la configuración apropiada de tu navegador. Sin embargo, debes saber que, si lo haces, este sitio no funcione adecuadamente.</p>
            <p>Según los términos incluidos en el artículo 22.2 de la Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico, si continúas navegando, estarás prestando tu consentimiento para el empleo de las cookies que detallo a continuación.</p>
            <p> Las cookies de esta web ayudan a:</p>
            <ul>
            <li>Hacer que esta web funcione correctamente</li>
            <li>Ahorrarle el tener que iniciar sesión cada vez que visitas este sitio</li>
            <li>Recordarte tus ajustes durante y entre las visitas</li>
            <li>Permitirte visualizar videos</li>
            <li>Mejorar la velocidad / seguridad del sitio</li>
            <li>Que pueda compartir páginas con redes sociales</li>
            <li>Mejorar continuamente de este sitio web</li>
            <li>Mostrarte anuncios en función de tus hábitos de navegación</li>
            </ul>
            <p>No utilizare jamás cookies para:</p>
            <ul>
            <li>Recoger información de identificación personal (sin tu permiso expreso)</li>
            <li>Recoger información sensible (sin tu permiso expreso)</li>
            <li>Compartir datos de identificación personal a terceros</li>
            </ul>
            <h2>2. Cookies de terceros que utilizamos en esta web y que debes conocer</h2>
            <p>Esta web, como la mayoría de sitios web, incluye funcionalidades proporcionadas por terceros.</p>
            <p>También se ponen a prueba regularmente nuevos diseños o servicios de terceros para recomendaciones e informes.</p>
            <p>Esto puede modificar ocasionalmente la configuración de cookies y que aparezcan cookies no detalladas en la presente política. Es importante que sepas que son cookies provisionales que no siempre es posible informar y que solo tienen finalidades de estudio y valoración. En ningún caso se van a utilizar cookies que comprometan tu privacidad.</p>
            <p>Entre las cookies de terceros más estables están:</p>
            <ul>
            <li>Las generadas por servicios de análisis, concretamente, Google Analytics para ayudar al website a analizar el uso que hacen los Usuarios del sitio web y mejorar la usabilidad del mismo, pero en ningún caso se asocian a datos que pudieran llegar a identificar al usuario.</li>
            </ul>
            <p>Google Analytics, es un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos ("Google").</p>
            <p>El usuario puede consultar <a href="https://www.google.es/intl/es/policies/technologies/types/">aquí</a> el tipo de cookies utilizadas por Google. Cookie de Google+ y Google Maps, según lo dispuesto en su página sobre qué tipo de cookies utilizan.</p>
            <ul>
            <li>WordPress:com es usuaria de la plataforma de suministro y alojamiento de blogs WordPress, propiedad de la empresa norteamericana Automattic, Inc. A tales efectos, el uso de tales cookies por los sistemas no nunca están bajo control o gestión de la responsable de la web, pueden cambiar su función en cualquier momento, y entrar cookies nuevas.</li>
            </ul>
            <p>Estas cookies tampoco reportan al responsable de este web beneficio alguno. Automattic, Inc., utiliza además otras cookies con la finalidad de ayudar a identificar y rastrear a los visitantes de los sitios de WordPress, conocer el uso que hacen del sitio web de Automattic, así como sus preferencias de acceso al mismo, tal y como se recoge en el apartado "Cookies" de su política de privacidad.</p>
            <ul>
            <li>Cookies de redes sociales: Las Cookies de redes sociales pueden almacenarse en su navegador mientras navega por sandrayelmer.com por ejemplo, cuando utiliza el botón de compartir contenidos de sandrayelmer.com en alguna red social.</li>
            </ul>
            <p>Las empresas que generan estas cookies correspondientes a las redes sociales que utiliza esta web tienen sus propias políticas de cookies:</p>
            <ul>
            <li>Cookie de Twitter, según lo dispuesto en su<a href="https://twitter.com/privacy">política de cookies</a></li>
            <li>Cookie de Facebook, según lo dispuesto en su <a href="https://www.facebook.com/help/cookies?ref_type=sitefooter">Política de Cookies</a></li>
            <li>Cookie de Youtube, según lo dispuesto en su <a href="https://www.google.es/intl/es/policies/technologies/cookies/">Política de Cookies</a></li>
            <li>Cookies de Instagram, ver más información en su <a href="https://www.facebook.com/policies/cookies/">política de cookies</a></li>
            <li>Cookies de Linkedin, ver más información en su <a href="https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies">política de cookies</a></li>
            </ul>
            <p>Las implicaciones de privacidad estarán en función a cada red social y dependerán de la configuración de privacidad que ha elegido en estas redes. En ningún caso, ni el responsable de esta web ni los anunciantes pueden obtener información personal identificable de estas cookies.</p>
            <p>Llevo a cabo acciones de remarketing mediante Google AdWords, que utiliza las cookies para ayudarme a ofrecer anuncios en línea específico basado en visitas anteriores a mi sitio web. Google utiliza esta información para mostrar anuncios en varios sitios web de terceros a través de Internet. Estas cookies están a punto de expirar y no contienen información que pueda identificarle personalmente. Por favor dirígete a la <a href="http://www.google.com/privacy/ads/">publicidad de Google Aviso de privacidad</a> para obtener más información.</p>
            <p>Llevo a cabo acciones de remarketing mediante Facebook ads, que utiliza las cookies para ayudarme a ofrecer anuncios en línea específico basado en visitas anteriores a mi sitio web.</p>
            <p> A continuación, y según exige el artículo 22.2 de la LSSI se detallan las cookies que pueden instalarse habitualmente durante la navegación por este sitio web:</p>

            <table>
                <thead>
                    <tr>
                        <th><h3>NOMBRE</h3></th>
                        <th><h3>DURACIÓN</h3></th>
                        <th><h3>FINALIDAD</h3></th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td><p>Terceros persistente _ga</p></td>
                    <td><p>2 años a partir de la configuración</p></td>
                    <td><p>Se usa para distinguir a los usuarios. Es una cookie perteneciente a Google Analytics. <a href="https://www.google.com/intl/es/policies/">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente _gat</p></td>
                    <td><p>10 minutos</p></td>
                    <td><p>Se usa para saber el ratio de recarga. Es una cookie perteneciente a Google Analytics. <a href="https://www.google.com/intl/es/policies/">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente __utma</p></td>
                    <td><p>2 años desde cada actualización</p></td>
                    <td><p>Esta cookie se suele establecer durante la primera visita. Si la cookie es eliminada manualmente, volverá a establecerse en la siguiente visita con ID nuevo. En la mayoría de los casos esta cookie se utiliza para determinar visitantes únicos a nuestro sitio y es actualizada con cada página vista. Además esta cookie tiene un ID único de Google Analytics que asegura tanto la validez de la misma como su accesibilidad como una medida de seguridad adicional. <a href="https://www.google.com/intl/es/policies/">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente __utmb</p></td>
                    <td><p>2 años desde cada actualización</p></td>
                    <td><p>Esta cookie se utiliza para establecer y continuar una sesión de usuario en nuestro sitio. Cuando un usuario visita una página en nuestro sitio, el código de Google Analytics intenta actualizar esta cookie. Si no encuentra la cookie, creará una nueva. Cada vez que el usuario visita una página distinta se actualiza esta cookie para caducar a los 30 minutos, de esa forma continúa encontrar una sesión única en intervalos de 30 minutos. Esta cookie caduca cuando un usuario deja de visitas nuestro sitio por un período de 30 minutos. <a href="https://www.google.com/intl/es/policies/">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente __utmt</p></td>
                    <td><p>De sesión</p></td>
                    <td><p>Historicamente, esta cookie operaba junto con la cookie __utmb para determinar si se debía establecer una nueva sesión al usuario. Hoy en día no se utiliza pero sigue aquí para mantener compatibilidad hacia atrás con el código de theurchin.js y ga.js. <a href="https://www.google.com/intl/es/policies/">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente __utmz</p></td>
                    <td><p>6 meses desde cada actualización</p></td>
                    <td><p>Esta cookie guarda información de referencias utilizadas por el visitante para llegar a nuestro sitio, ya sea de forma directa, un link de referencia, una búsqueda web o una campaña de publicidad o por email. Se utiliza para calcular el tráfico proviniente de buscadores web, campañas de marketing y navegación por nuestro sitio. Esta cookie se actualiza en cada vista de página. <a href="https://www.google.com/intl/es/policies/">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente fr</p></td>
                    <td><p>90 días</p></td>
                    <td><p>Se trata de la cookie de publicidad principal de Facebook. Se utiliza para ofrecer, analizar y mejorar la relevancia de los anuncios. <a href="https://www.facebook.com/policy/cookies/printable">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente sb</p></td>
                    <td><p>2 años desde su instalación</p></td>
                    <td><p>Cookie de Facebook que identifica al navegador con fines de autenticación del inicio de sesión. <a href="https://www.facebook.com/policy/cookies/printable">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente c_user</p></td>
                    <td><p>20 días desde su instalación</p></td>
                    <td><p>Se utiliza junto con la cookie xs para autenticar tu identidad en Facebook. <a href="https://www.facebook.com/policy/cookies/printable">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p>Terceros persistente xs</p></td>
                    <td><p>90 días desde su instalación</p></td>
                    <td><p>Se utiliza junto con la cookie c_user para autenticar tu identidad en Facebook. <a href="https://www.facebook.com/policy/cookies/printable">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p>APISID, HSID, NID, SAPISID, SID, SSID</p></td>
                    <td><p>2 años desde su instalación</p></td>
                    <td><p>Guarda preferencias de usuario y otra información de los servicios de Google que tiene el usuario. <a href="https://www.google.com/policies/privacy/">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente datr</p></td>
                    <td><p>2 años desde su instalación</p></td>
                    <td><p>De Facebook que identifica a los navegadores con fines de seguridad e integridad del sitio, entre ellos, la recuperación de cuentas y la identificación de cuentas que puedan estar en riesgo. <a href="https://www.facebook.com/policy/cookies/printable">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p> Terceros persistente __smToken</p></td>
                    <td><p>Caduca al finalizar la sesión</p></td>
                    <td><p>Se usa para diferenciar los usuarios a los que se les ha mostrado alguna funcionalidad de Sumome. <a href="https://sumo.com/tos">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p>Terceros__zlcmid</p></td>
                    <td><p>Caduca al finalizar la sesión</p></td>
                    <td><p>Son cookies utilizadas por la herramienta ZOPIM para permitir chatear en directo con la atención al cliente. <a href="https://es.zopim.com/">Más información</a></p></td>
                </tr>
                <tr>
                    <td><p>Vimeo</p></td>
                    <td><p>2 años desde cada actualización</p></td>
                    <td><p>Nos permite incrustar vídeos de Vimeo. Este modo puede establecer cookies en su ordenador una vez que haga clic en el reproductor de vídeo de Vimeo, pero Vimeo no almacenará información de las cookies de identificación personal de reproducciones de vídeos incrustados</p></td>
                </tr>
                </tbody>
            </table>  
            
            <h2><strong>3. &iquest;Cómo puedo gestionar y desactivar estas cookies?</strong></h2>
            <p>En el caso de no desear que el sitio web no instale ninguna cookie en tu equipo, cabe la posibilidad de adaptar tu navegador de modo que se te notifique antes de que se descargue ninguna cookie.</p>
            <p>Así las cosas, se puede igualmente modificar la configuración del navegador de forma que rechace todas las cookies o únicamente las cookies de terceros. También puedes eliminar cualquiera de las cookies que ya se encuentren en tu equipo. Ten en cuenta que tendrás que adaptar por separado la configuración de cada navegador y equipo que utilices.</p>
            <p><strong>{ sitioWeb }</strong> pone a disposición de los usuarios que quieran impedir la instalación de las mencionadas cookies, enlaces facilitados al efecto por navegadores cuyo uso se considera más extendido:</p>
            <p><a href="http://support.google.com/chrome/bin/answer.py?hl=es-419&amp;answer=95647">Google Chrome</a> &ndash; <a href="http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-10">Internet Explorer</a> &ndash; <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">Mozilla Firefox</a> &ndash; <a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES%20%97%20http://support.apple.com/kb/ph5042">Apple Safari</a></p>
            <h2><strong>4. MÁS INFORMACIÓN SOBRE LAS COOKIES</strong></h2>
            <p>Puedes consultar el reglamento sobre cookies publicado por la Agencia Española de Protección de Datos en su "Guía sobre el uso de las cookies" y obtener más información sobre las cookies en Internet, <a href="http://www.aboutcookies.org/">http://www.aboutcookies.org/</a></p>
            <p>Si desea tener un mayor control sobre la instalación de cookies, puede instalar programas o complementos a su navegador, conocidos como herramientas de "Do Not Track", que le permitirán escoger aquellas cookies que desea permitir.</p>
    <p><strong>Esta política de cookies ha sido actualizada por última vez el {fecha.getDate()}/{fecha.getMonth() + 1}/{fecha.getFullYear()}</strong></p>
        </div>
    )
}

export default PoliticaCookies;

import React, {useState, createContext} from 'react';

export const FormularioContext = createContext();

const FormularioProvider = (props) => {

    // Datos de la web
    const [nombreCompleto, setNombreCompleto] = useState('');
    const [responsable, setResponsable] = useState('');
    const [nombreComercial, setNombreComercial] = useState('')
    const [cif, setCif] = useState({ numero: '', tipo: 'CIF/NIF' });
    const [direccion, setDireccion] = useState('');
    const [email, setEmail] = useState('');
    const [actividadSocial, setActividadSocial] = useState('');
    const [sitioWeb, setSitioWeb] = useState('');
    const [politicasPrivacidad, setPoliticasPrivacidad] = useState('/politicas-de-privacidad');

    // Servicios ofrecidos por terceros
    const [nombreHosting, setNombreHosting] = useState('Sered');
    const [tirdEmailCamp, setTirdEmailCamp] = useState('GetResponse');
    const [tirdURLEmailCamp, setTirdURLEmailCamp] = useState('https://www.getresponse.es/');
    const [tirdWPTheme, setTirdWPTheme] = useState('Hello Elementor');
    const [tirdURLWPTheme, setTirdURLWPTheme] = useState('https://elementor.com/hello-theme/');
    const [tirdPasarelaPago, setTirdPasarelaPago] = useState('');
    const [tirdContabilidad, settirdContabilidad] = useState('');
    const [tirdWebinars, setTirdWebinars] = useState('Zoom');
    const [tirdURLWebinars, setTirdURLWebinars] = useState('https://zoom.us/');
    const [tirdAlojamientoCursos, setTirdAlojamientoCursos] = useState('');
    const [tirdURLAlojamientoCursos, setTirdURLAlojamientoCursos] = useState('')
    // No requridos
    const [tirdPlataformaPago, setTirdPlataformaPago] = useState('');
    const [tirdURLPlataformaPago, setTirdURLPlataformaPago] = useState('');

    // Proveedor de hosting
    const [responsableHosting, setResponsableHosting] = useState('XTUDIO NETWORKS, S.L.U.');
    const [urlHosting, setUrlHosting] = useState('http://hostingdesarrollatonline.com/');
    const [direccionHosting, setDireccionHosting] = useState('C/ Progreso 177, A Rua, 32350, Ourense.');
    const [cifHosting, setCifHosting] = useState('B-32435315');
    const [legalHosting, setLegalHosting] = useState('https://sered.net/politica-de-privacidad')

    return (
        <FormularioContext.Provider value= {{
            nombreCompleto, setNombreCompleto,
            responsable, setResponsable,
            nombreComercial, setNombreComercial,
            cif, setCif,
            direccion, setDireccion,
            email, setEmail,
            actividadSocial, setActividadSocial,
            sitioWeb, setSitioWeb,
            politicasPrivacidad, setPoliticasPrivacidad,
            nombreHosting, setNombreHosting,
            tirdEmailCamp, setTirdEmailCamp,
            tirdURLEmailCamp, setTirdURLEmailCamp,
            tirdWPTheme, setTirdWPTheme,
            tirdURLWPTheme, setTirdURLWPTheme,
            tirdPasarelaPago, setTirdPasarelaPago,
            tirdContabilidad, settirdContabilidad,
            tirdWebinars, setTirdWebinars,
            tirdURLWebinars, setTirdURLWebinars,
            tirdAlojamientoCursos, setTirdAlojamientoCursos,
            tirdURLAlojamientoCursos, setTirdURLAlojamientoCursos,
            tirdPlataformaPago, setTirdPlataformaPago,
            tirdURLPlataformaPago, setTirdURLPlataformaPago,
            responsableHosting, setResponsableHosting,
            urlHosting, setUrlHosting,
            direccionHosting, setDireccionHosting,
            cifHosting, setCifHosting,
            legalHosting, setLegalHosting
        }}>
            {props.children}
        </FormularioContext.Provider>
    )
}

export default FormularioProvider

import React from 'react';
import styled from '@emotion/styled';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import './App.css';
import Footer from './components/static/Footer'
import NavBar from './components/static/NavBar';
import FormularioProvider from './context/formularioContext';
import politicaPrivacidad from './components/PoliticaPrivacidad';
import PoliticaCookies from './components/PoliticaCookies';
import avisoLegal from './components/AvisoLegal';
import Navegacion from './components/Navegacion';

const Contenedor = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 40px auto;
  padding: 30px;
  border-radius: 10px;
  /* border-top: solid 10px #4D89BF; */
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  background-color: #ffffff;
`;



function App() {
  const copiarTexto = () => {   
    const codigoACopiar = document.getElementById('textoACopiar');
    const seleccion = document.createRange();
    seleccion.selectNodeContents(codigoACopiar);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(seleccion);
    const res = document.execCommand('copy');
    window.getSelection().removeRange(seleccion);
  }

  return (
    <FormularioProvider>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 navegacion pb-5" id="style-3">
            <h4 className="text-center pt-3 ">Datos RGPD</h4>
            <Navegacion />
          </div>
          <div className="col-md-9 rgpd">          
            <Router>
              <NavBar />
              <Contenedor id="textoACopiar" style={{marginTop: '80px'}}>
              <div className="d-flex align-items-end flex-column">
                <button 
                className="btn btn-primary"
                onClick={copiarTexto}>Copiar</button>
              </div>
              
                <Switch>
                  <Route path="/aviso-legal" exact component={avisoLegal} />
                  <Route path="/politica-de-cookies" exact component={PoliticaCookies} />
                  <Route path="/" exact component={politicaPrivacidad} />
                </Switch>
              </Contenedor>
            </Router>
          </div>
        </div>
      </div>
    
      <Footer />
    </FormularioProvider>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom'; 
import './NavBar.css'

const NavBar = () => {
    return (
        <nav className="navbar navbar-dark bg-primary navbar-expand fijo">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav">
                    <Link className="nav-item nav-link" to="/">Políticas de Privacidad</Link>
                    <Link className="nav-item nav-link" to="/politica-de-cookies">Políticas de cookies</Link>
                    <Link className="nav-item nav-link" to="/aviso-legal">Aviso legal</Link>
                </div>
            </div>
        </nav>
    )
}

export default NavBar

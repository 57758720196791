import React from 'react';

const Footer = () => {
    
    return (
        <footer className="container-fluid py-4 bg-dark">
            <div className="container text-light text-center">
               &copy; Copyright { new Date().getFullYear() } todos los derechos reservados.
            </div>           
        </footer>
    )
}

export default Footer
